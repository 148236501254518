import * as React from 'react';
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const items = [
  {
    title: 'Web Application',
    description: 'Accelerate growth and elevate your brand\'s digital presence with our powerhouse digital enabler. Reduce feedback cycle time by 30%, giving managers timely insights for faster decision-making and improved growth tracking.',
    image: 'images/services/web.jpg',
    link: '/services/web-application',
  },
  {
    title: 'UI/UX & Design Services',
    description: 'Transform your brand with an unforgettable user experience that turns visitors into loyal customers. By implementing intuitive design and streamlined navigation, we boosted user engagement by 40%, leading to a 15% increase in web transactions.',
    image: 'images/services/uiux.jpg',
    link: '/services/uiux-design',
  },
  {
    title: 'Digital Growth Solutions',
    description: 'From SEO to targeted ads, our tailored strategies drive high-quality traffic that converts into loyal customers. With expertise across various countries and industries, we boost your visibility and deliver impactful results.',
    image: 'images/services/seo.jpg',
    link: '/services/seo-marketing',
  }
]

export default function ServiceCards() {
  const theme = useTheme(); // Get the current theme
  const navigate = useNavigate();

  const handleItemClick = (link: string) => {
    window.scrollTo(0, 0);
    navigate(link);
  };

  return (
    <>
      <Container id="service-cards" sx={{ marginTop: '5%' }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
            }}
          >
            Our Services
          </Typography>

          <Typography
            textAlign="center"
            color="text.primary"
            sx={{
              fontSize: '1.15rem',
              fontWeight: 500,
              lineHeight: 1.6,
              alignSelf: 'center',
              width: { sm: '100%', md: '80%' }
            }}
            dangerouslySetInnerHTML={{ __html: "Explore our comprehensive range of services designed to propel your business forward. Whether you need custom web applications, enhanced online visibility, or captivating design, our expertise ensures your success in a digital-first world." }}
          />
        </Stack>
      </Container>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px', marginTop: '3%' }}>
        <Container sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', gap: 2 }}>
          {
            items.map((item: any) => (
              <Card sx={{ maxWidth: { xs: '100%', sm: 345 }, marginBottom: { xs: 2, sm: 0 } }} key={item.title}>
                <CardMedia
                  sx={{ height: 300 }}
                  image={item.image}
                  title={item.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {item.description}
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button onClick={() => handleItemClick(item.link)} size="small">Learn More {`>`}</Button>
                </CardActions> */}
              </Card>
            ))
          }
        </Container>
      </Box>
    </>
  );
}
