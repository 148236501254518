import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

const CursorImage = () => {
  // Check if the screen width is less than or equal to 600px
  const isMobile = useMediaQuery('(max-width:600px)');

  // If it's mobile, render nothing
  if (isMobile) return null;

  // Get the current window width
  const windowWidth = window.innerWidth;

  // Calculate opacity based on the window width
  const opacity = Math.min(Math.max((windowWidth - 600) / (1366 - 600), 0), 1) * 100;

  return (
    <Box
      sx={{
        position: 'absolute', // Allows it to cover the viewport
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(/images/homepage_cursors.svg)`,
        backgroundSize: '80%', // Adjust this value to make the image smaller
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
        zIndex: -1, // Keeps it behind other content
        opacity: `${opacity}%`, // Set opacity dynamically
        transition: 'opacity 0.3s ease-in-out', // Smooth transition for opacity changes
      }}
    />
  );
};

export default CursorImage;
