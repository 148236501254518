import React from 'react';
import { Divider, PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import getLPTheme from '../getLPTheme';
import Hero from '../components/Hero';
import { useThemeMode } from '../hooks/ThemeModeContext';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import { DarkFooter } from '../components/DarkFooter';

const ContactUsPage = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box id="contact-us" sx={{ bgcolor: 'background.default' }}>
        <Hero
          prefixTitle='Get'
          title='in Touch'
          description={`We're here to help—reach out to us for any inquiries, collaborations, or support, and we'll get back to you promptly.`}
        />
        <ContactUs />
        <DarkFooter />
      </Box>
    </ThemeProvider>
  );
}

export default ContactUsPage;
