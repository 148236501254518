import React from 'react';
import { Box, Typography, Grid, Container, Paper } from '@mui/material';

const StartupStatistics = () => {
  return (
    <Container maxWidth="lg" sx={{ my: 8 }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Statistic 1 */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#ff1744' }}>
              90%
            </Typography>
            <Typography variant="subtitle1">
              The failure rate for new startups.
            </Typography>
          </Paper>
        </Grid>

        {/* Statistic 2 */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#ff9800' }}>
              $3,000
            </Typography>
            <Typography variant="subtitle1">
              Average cost of launching a startup.
            </Typography>
          </Paper>
        </Grid>

        {/* Statistic 3 */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
              34%
            </Typography>
            <Typography variant="subtitle1">
              Lack proper product-market fit.
            </Typography>
          </Paper>
        </Grid>

        {/* Statistic 4 */}
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
              22%
            </Typography>
            <Typography variant="subtitle1">
              Don’t have a sound marketing strategy.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StartupStatistics;
