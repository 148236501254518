import React from 'react';
import { Box, Container, Grid, Typography, Avatar, Link, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const leaders = [
  {
    name: 'Giovanny',
    position: 'CEO',
    linkedin: 'https://www.linkedin.com/in/giovannyputri/',
    photo: '/images/people/gio.jpeg',
    bio: `With a Bachelor's degree in Psychology and an impressive years of tenure as a tech leaders, she bring a unique blend of expertise in understanding human behavior and technological acumen.`
  },
  {
    name: 'Delphito',
    position: 'CTO',
    linkedin: 'https://www.linkedin.com/in/bartolomeus-delphito/',
    photo: '/images/people/delphito.jpeg',
    bio: 'With a Master of Software Engineering from Carnegie Mellon University, his extensive expertise in digital applications significantly enhances our technological capabilities and innovation strategies'
  },
  {
    name: 'Noverando',
    position: 'CBO',
    linkedin: 'https://www.linkedin.com/in/noverando-rafiel-angelo/',
    photo: '/images/people/verdo.jpeg',
    bio: 'Holding a combined background in Science and a Master’s degree in Venture Creation from the National University of Singapore, he leverages his scientific insight and business acumen to drive transformative growth and innovation within the company.'
  },
  {
    name: 'Jane Mitha',
    position: 'CMO',
    linkedin: 'https://www.linkedin.com/in/janemitha/',
    photo: '/images/people/jane.jpeg',
    bio: 'A pragmatic optimist with a deep passion for SEO, she expertly aligns data and content to implement best practices for maximizing sales and lead generation. Her strategies have consistently delivered long-term traffic growth'
  },
  {
    name: 'Savira Azaria',
    position: 'CPO',
    linkedin: 'https://www.linkedin.com/in/savirazaria/',
    photo: '/images/people/savira.jpeg',
    bio: 'With a proven track record in developing brand identities for multiple companies, her creative talent was recognized with a 1st place award in the Flash Design Competition by Kominfo.'
  },
];

const Leadership = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Grid container spacing={4} justifyContent={'center'}>
        {leaders.map((leader, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="240"
                image={leader.photo}
                alt={`${leader.name} photo`}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                  <Box>
                    <Typography variant="h6" component="h2">
                      {leader.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {leader.position}
                    </Typography>
                  </Box>
                  <IconButton
                    aria-label="LinkedIn profile"
                    href={leader.linkedin}
                    target="_blank"
                    rel="noopener"
                  >
                    <LinkedInIcon sx={{ color: '#0077B5' }} />
                  </IconButton>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {leader.bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Leadership;
