import React, { ReactNode, useState, useEffect } from 'react';

// Custom hook to determine if the screen is mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

interface BlueGradientBoxProps {
  children: ReactNode;
}

export const BlueGradientBox: React.FC<BlueGradientBoxProps> = ({ children }) => {
  const isMobile = useIsMobile(); // Use custom hook to detect if the device is mobile

  return (
    <div
      style={{
        position: 'relative',
        height: '250px',
        width: '100%',
        background: 'radial-gradient(circle, #1e3a8a, #0b2545)', // Darker shades of blue
        borderRadius: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        color: '#fff',
        textAlign: 'center',
      }}
    >
      {/* Only render images if not on mobile */}
      {!isMobile && (
        <>
          <img
            src={`images/cursors/webd.svg`}
            alt="svg1"
            style={{
              position: 'absolute',
              top: '60%',
              left: '20%',
              width: '100px',
              height: '100px',
              opacity: '0.6',
            }}
          />
          <img
            src={`images/cursors/seos.svg`}
            alt="svg2"
            style={{
              position: 'absolute',
              top: '40%',
              left: '5%',
              width: '100px',
              height: '100px',
              opacity: '0.6',
            }}
          />
          <img
            src={`images/cursors/digi.svg`}
            alt="svg3"
            style={{
              position: 'absolute',
              top: '40%',
              left: '85%',
              width: '100px',
              height: '100px',
              opacity: '0.6',
            }}
          />
          <img
            src={`images/cursors/anim.svg`}
            alt="svg4"
            style={{
              position: 'absolute',
              top: '65%',
              left: '70%',
              width: '100px',
              height: '100px',
              opacity: '0.6',
            }}
          />
        </>
      )}
      {children}
    </div>
  );
};
