import React from 'react';
import { Box, Container, Grid, Typography, Card, CardMedia } from '@mui/material';

const CaseStudy = ({ title, subTitle, problem, solution, result, images }: any) => {

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      {/* <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography> */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          color: (theme) =>
            theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
        }}>
        {subTitle}
      </Typography>


      {/* Problem */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Problem
        </Typography>
        <Typography variant="body1">
          {problem}
        </Typography>
      </Box>

      {/* Proposed Solution */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Proposed Solution
        </Typography>
        <Typography variant="body1">
          {solution}
        </Typography>
      </Box>

      {/* End Result */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          End Result
        </Typography>
        <Typography variant="body1">
          {result}
        </Typography>
      </Box>

      {/* Galleries */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Galleries
        </Typography>
        <Grid container spacing={4} direction="column">
          {images.map((image: any, index: any) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height={image.height}
                  image={image.url}
                  alt={`Gallery Image ${index + 1}`}
                  sx={{ objectFit: 'cover' }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CaseStudy;
