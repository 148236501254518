import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';

const flags = [
  { name: 'Indonesia', code: 'ID' },
  { name: 'Singapore', code: 'SG' },
  { name: 'France', code: 'FR' },
  { name: 'USA', code: 'US' },
  { name: 'Russia', code: 'RU' },
  { name: 'UK', code: 'GB' },
  { name: 'Norway', code: 'NO' },
];

function FlagDisplay() {
  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {flags.map((flag) => (
          <Grid item key={flag.code}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ReactCountryFlag
                countryCode={flag.code}
                svg
                style={{
                  width: '60px',
                  height: '40px',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                }}
                title={flag.name}
              />
              <Typography variant="subtitle1" align="center" sx={{ mt: 1, color: 'text.primary' }}>
                {flag.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default FlagDisplay;
