import { useEffect, useState } from "react"

export const BlackAndWhiteTransition = ({ rootMode }: { rootMode: string }) => {
  if (rootMode === 'dark') {
    return null
  }

  return (
    <div
      style={{
        height: '100px',
        background: 'linear-gradient(to bottom, #000000, #333333, #666666, #cccccc, #ffffff)',
      }}
    />
  )
}

export const WhiteToBlackTransition = ({ rootMode }: { rootMode: string }) => {
  if (rootMode === 'dark') {
    return null
  }

  return (
    <div
      style={{
        height: '100px',
        background: 'linear-gradient(to bottom, #ffffff, #cccccc, #666666, #333333, #000000)',
      }}
    />
  )
}

export const BlackToWhiteRoundedShape = ({ rootMode }: { rootMode: string }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  if (isMobile) {
    return (
      <div
        style={{
          height: '50px',
        }}
      />
    )
  }

  if (rootMode === 'dark') {
    return (
      <div
        style={{
          height: '100px',
          backgroundColor: 'hsl(220, 30%, 3%)',
          borderBottomLeftRadius: '50%',
          borderBottomRightRadius: '50%',
          width: '100%',
        }}
      />
    );
  }

  return (
    <div
      style={{
        height: '100px',
        backgroundColor: 'black',
        borderBottomLeftRadius: '50%',
        borderBottomRightRadius: '50%',
        width: '100%',
      }}
    />
  );
};

export const WhiteToBlackRoundedShape = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  if (isMobile) {
    return null;
  }

  return (
    <div
      style={{
        height: '100px',
        backgroundColor: 'hsl(220, 30%, 3%)',
        borderTopLeftRadius: '50%',
        borderTopRightRadius: '50%',
        width: '100%',
      }}
    />
  );
};
