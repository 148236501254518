import React from 'react';
import { Box } from '@mui/material';
import { useThemeMode } from '../../hooks/ThemeModeContext';

const ImageDisplayer = ({ items, selectedItemIndex }: any) => {
  const { mode } = useThemeMode();

  const currentItem = items[selectedItemIndex];
  const isIcon = typeof currentItem.imageLight === 'object' && currentItem.imageLight.type;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 280,
        width: '100%', // Ensure the Box takes full width
        ...(isIcon
          ? {} // No background styling needed for icons
          : {
            backgroundImage: `url(${mode === 'light'
              ? currentItem.imageLight
              : currentItem.imageDark})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }),
      }}
    >
      {isIcon && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {mode === 'light' ? currentItem.imageLight : currentItem.imageDark}
        </Box>
      )}
    </Box>
  );
};

export default ImageDisplayer;
