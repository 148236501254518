import React from 'react';
import { Divider, PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Services from '../../components/ProductsAndServices/Services';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../../getLPTheme';
import Hero from '../../components/Hero';
import { useThemeMode } from '../../hooks/ThemeModeContext';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';

const ServicesPage = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Hero prefixTitle='Our' title='Services' description='Discover our range of specialized services designed to accelerate your business growth and enhance your digital footprint.' />
        <Services />
        <ContactUs />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default ServicesPage;
