import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import AppAppBar from '../components/AppAppBar';
import LogoCollection from '../components/Home/LogoCollection';
import World from '../components/Home/World';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Products from '../components/ProductsAndServices/Products';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import getLPTheme from '../getLPTheme';
import { useThemeMode } from '../hooks/ThemeModeContext';
import Hero from '../components/Hero';
import ImageBox from '../components/ImageBox';
import Services from '../components/ProductsAndServices/Services';
import HeaderButtons from '../components/Home/HeaderButtons';
import StatisticsHeader from '../components/Home/StatisticsHeader';
import Starfield from '../components/Home/Starfield';
import CursorImage from '../components/Home/CursorImage';
import InfoTabs from '../components/Home/InfoTabs';
import HomeHero from '../components/Home/HomeHero';
import LandingWrapper from '../components/Home/LandingWrapper';
import ServiceCards from '../components/ServicesV2/ServiceCards';
import ClientLogoBanner from '../components/Home/ClientLogoBanner';
import AddOns from '../components/Home/AddOns';
import { BlackAndWhiteTransition, BlackToWhiteRoundedShape, WhiteToBlackRoundedShape, WhiteToBlackTransition } from '../atoms/BWTransition';
import CallToActionBox from '../components/Home/CallToActionBox';

export default function LandingPage() {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <LandingWrapper />
      {/* <HomeHero />
      <div style={{ height: '10vh' }}></div>
      <HeaderButtons />
      <Starfield />
      <CursorImage />
      <div style={{ height: '40vh' }}></div> */}
      {/* <StatisticsHeader /> */}
      <BlackToWhiteRoundedShape rootMode={mode} />
      <ClientLogoBanner />
      <Box sx={{ bgcolor: 'background.default', paddingTop: '5%' }}>
        <World />
      </Box>
      <Box sx={{ bgcolor: 'background.default' }}>
        {/* <World /> */}
        {/* <LogoCollection /> */}
        {/* <ServiceCard /> */}
        <ServiceCards />
        <AddOns />
        {/* <Services /> */}
        <DarkSection rootMode={mode} />
      </Box>
    </ThemeProvider>
  );
}

const DarkSection = ({ rootMode }: { rootMode: string }) => {
  const darkLPTheme = createTheme(getLPTheme('dark'));
  return (
    <ThemeProvider theme={darkLPTheme}>
      <WhiteToBlackRoundedShape />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Products />
        <Divider />
        <Testimonials />
        <Divider />
        {/* <Highlights /> */}
        {/* <Divider /> */}
        {/* <Pricing /> */}
        <Divider />
        <FAQ />
        <Divider />
        <CallToActionBox />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}