import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../../getLPTheme';
import Hero from '../../components/Hero';
import CaseStudy from '../../components/ProductsAndServices/CaseStudy';
import { useThemeMode } from '../../hooks/ThemeModeContext';
import ContactUs from '../../components/ContactUs';
import { CardMedia, Container, Divider, Grid, Typography } from '@mui/material';
import Footer from '../../components/Footer';
import StatiscticPaper from '../../atoms/StatiscticPaper';

const images = [] as any

const Seo = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Hero prefixTitle='SEO &' title={'Marketing'} description={``} />

        <Container sx={{ py: 6 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Driving 50% Organic Traffic Growth
          </Typography>

          <Typography variant="h6">
            We've built more than 10+ websites differ in various industries specialized in e-commerce with more than 10M organic traffic. Both SEO and SEM play complementary roles in enhancing brand visibility, but they differ in their approach and execution.
          </Typography>

          <Grid container spacing={2} justifyContent="center" sx={{ mt: 10 }}>
            <Grid item xs={12} md={4}>
              <StatiscticPaper title="210%+" subTitle="Website Crawling Efficiency" minHeight={200} fontSize='3rem' backgroundColor='#0874e4'/>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatiscticPaper title="50%+" subTitle="Organic Traffic Increase" minHeight={200} fontSize='3rem' backgroundColor='#0874e4'/>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatiscticPaper title="8 Months" subTitle="Implementation" minHeight={200} fontSize='3rem' backgroundColor='#0874e4'/>
            </Grid>
          </Grid>

          <Typography variant="h5" sx={{ mt: 8 }}>
            What is the problem of listing automotive industry?
          </Typography>
          <Typography>
            The site’s organic traffic was plateauing at 5-6 million visits per month, limiting growth potential. Organic traffic shows a stagnant organic traffic growth. Meanwhile website has more than 1M pages, this indicates there's not efficient crawling. Website lacked proper SEO optimization, with issues such as unoptimized content, weak keyword strategy, and technical SEO flaws that hindered search engine rankings. Additionally, their previous marketing efforts had not effectively targeted the right audience, leading to a poor return on investment (ROI).
          </Typography>

          <Typography variant="h5" sx={{ mt: 8 }}>
            Our Ultimate Proposed Solution
          </Typography>
          <Typography>
            We conducted a comprehensive SEO audit to identify the key issues affecting the website’s performance. Our team then implemented a tailored SEO Assesment to fixed website stucture, schema, redirection loop issues & duplication issues. We do also suggest on-page optimization for every page category & create link building strategy for securing specific business keywords.
          </Typography>

          <Typography variant="h5" sx={{ mt: 8 }}>
            Our Optimization Results
          </Typography>
          <Typography>
            Achieved a 50% increase in organic traffic, growing from 5 million to 8.9 million visits per month aften 8 months of SEO Tech implementations. Website could acchieve 210% crawling efficiency by removing duplication causes. We succesfully cut 400k pages cause inefficiency crawling. Over 200 targeted keywords moved into the top 3 positions on Google, driving more qualified traffic to the site. Our team also providing keywords with high business intent for performance campaign which increase number of replies to website.
          </Typography>

          <CardMedia
            component="img"
            image={'/images/seo_chart_case_study.svg'}
            alt={`seo_chart_case_study.svg`}
            sx={{ objectFit: 'cover', mt: 8 }}
          />

        </Container>

        {/* <CaseStudy
          title="SEO Case Study 1 - A Medical Clinic"
          problem={`An e-commerce platform was struggling with low organic traffic and poor conversion rates, despite having a wide range of products and competitive pricing. Their website lacked proper SEO optimization, with issues such as unoptimized content, weak keyword strategy, and technical SEO flaws that hindered search engine rankings. Additionally, their previous marketing efforts had not effectively targeted the right audience, leading to a poor return on investment (ROI).`}
          solution={`We conducted a comprehensive SEO audit to identify the key issues affecting the website’s performance. Our team then implemented a tailored SEO strategy that included keyword research, on-page optimization, and content restructuring to align with best practices. We also addressed technical SEO challenges, such as improving site speed, fixing broken links, and optimizing the mobile experience. To further enhance visibility, we launched a targeted content marketing campaign that focused on high-intent keywords and created valuable content aimed at the client’s target audience.`}
          result={`Within six months, the e-commerce platform experienced a 150% increase in organic traffic and a 45% improvement in conversion rates. The site’s search engine rankings improved significantly, with several high-traffic keywords reaching the top positions on Google. The targeted content marketing efforts also led to a higher engagement rate and a substantial increase in sales, resulting in a much-improved ROI for the client.`}
          images={images}
        /> */}
        <ContactUs />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default Seo;
