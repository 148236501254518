import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: "What digital solution does ADN offer?",
    answer: `At ADN, we provide digital solutions to cover all aspects of your business touchpoints, including:
      <ul>
        <li>Internal systems</li>
        <li>App and website development</li>
        <li>Full-scale digital marketing</li>
      </ul>
      Our go-to-market strategy is tailored to your unique business objectives, starting with understanding your brand and target audience.
      We create robust digital assets and help you find effective marketing channels.`
  },
  {
    question: "How is ADN different from other digital agencies?",
    answer: `ADN is a professional digital agency based in Indonesia, offering:
      <ul>
        <li>Digital marketing</li>
        <li>SEO & PPC</li>
        <li>Web development</li>
        <li>Content production</li>
      </ul>
      Our strategic location allows us to deliver top-quality services at competitive prices, offering exceptional value.
      We continuously bring proven impact with our customized approach and commitment to excellence.`
  },
  {
    question: "What are the benefits of hiring ADN as a digital agency compared to building an in-house team?",
    answer: `Hiring ADN gives you access to an entire team of dedicated professionals, including:
      <ul>
        <li>Digital marketing experts</li>
        <li>SEO specialists</li>
        <li>Web developers</li>
        <li>Content creators</li>
      </ul>
      Unlike a single in-house hire, working with ADN gives you a full suite of expertise, delivering a comprehensive and strategic approach to meet your business goals effectively.`
  },
  {
    question: "Which digital solution does your company provide?",
    answer: `<strong>SEO & SEM Services:</strong>
      <ul>
        <li><strong>SEO:</strong> Improve your website’s visibility on search engines using techniques like keyword research, on-page optimization, and link-building.</li>
        <li><strong>Local SEO:</strong> Optimize for location-based searches (e.g., "near me") with strategies like Google My Business (GMB) optimization.</li>
        <li><strong>Ecommerce SEO:</strong> Enhance the visibility of your online store with optimized product pages and schema markup.</li>
        <li><strong>PPC:</strong> Use targeted ads on platforms like Google Ads and Bing Ads to reach specific audiences.</li>
        <li><strong>SEM:</strong> Integrate SEO and PPC strategies for maximum search engine visibility.</li>
      </ul>
      
      <strong>Website & App Development:</strong>
      <ul>
        <li><strong>Website Development:</strong> Create responsive, visually engaging websites using <b>JavaScript</b>, <b>HTML</b>, and <b>CSS</b>, enhanced by frameworks like <b>React</b> and <b>Vue</b>.</li>
        <li><strong>Web App Development:</strong> Develop dynamic web applications customized to meet your business needs using cutting-edge technologies such as <b>Node</b> and <b>Python</b>, paired with modern front-end frameworks like <b>React</b>, <b>React Native</b>, <b>Angular</b>, and <b>Vue</b>. We deploy these solutions on reliable cloud platforms like <b>AWS</b> and <b>Salesforce</b>, ensuring stability and scalability for your business growth.</li>
        <li><strong>Website Optimization:</strong> Improve site speed and user experience through optimization techniques like image compression and lazy loading.</li>
      </ul>
      
      <strong>UI/UX Design:</strong>
      <ul>
        <li><strong>UI Design:</strong> Create visually appealing interfaces using tools like Figma and Adobe XD.</li>
        <li><strong>UX Design:</strong> Improve user satisfaction through usability testing and wireframing to ensure smooth navigation.</li>
      </ul>
      
      <strong>Creative & Visual Content:</strong>
      <ul>
        <li><strong>Motion Graphics:</strong> Add depth and movement to graphics with Adobe After Effects and Cinema 4D.</li>
        <li><strong>Product Video Launches:</strong> Demonstrate product features with high-quality videography and 3D animations.</li>
        <li><strong>Photography & Videography:</strong> Produce professional-grade images and videos using high-resolution equipment.</li>
      </ul>
      
      <strong>Social Media Optimization & Marketing:</strong>
      <ul>
        <li><strong>Optimize Profiles:</strong> On platforms like Instagram, Facebook, and LinkedIn.</li>
        <li><strong>Social Media Management:</strong> Use tools like Hootsuite and Buffer for scheduling and engagement.</li>
        <li><strong>Content Marketing:</strong> Create targeted, SEO-friendly content including blogs, infographics, and social media updates.</li>
      </ul>`
  },
  {
    question: "How can I get in touch with ADN to discuss my digital needs?",
    answer: `You can reach us through our website’s contact form or email <a href="mailto:angelo@adndigitalsolution.com" style="color: #1e90ff; text-decoration: none;">angelo@adndigitalsolution.com</a>.
      We’re ready to discuss how ADN can support your business growth.`
  }
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          mb: 4,
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: { sm: '100%', md: '70%' },
                  lineHeight: 1.8, // Increase line height for better readability
                }}
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
