import React from 'react';
import { Box, Button, Typography, Paper, Container } from '@mui/material';
import { styled } from '@mui/system';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import SearchIcon from '@mui/icons-material/Search';
import CampaignIcon from '@mui/icons-material/Campaign';
import SocialMediaIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import WebIcon from '@mui/icons-material/Web';
import AutomationIcon from '@mui/icons-material/Autorenew';
import BuildIcon from '@mui/icons-material/Build';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GradientButton from '../Atomics/GradientButton';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  height: '250px', // Ensures all cards have the same height
}));

const addOnsData = [
  {
    title: 'Paid Ad Campaigns',
    description: 'Creating, managing, and optimizing PPC campaigns across Google Ads, Facebook, Instagram, etc.',
    icon: <CampaignIcon fontSize="large" color="primary" />,
  },
  {
    title: 'Social Media Management',
    description: 'Full-service social media content creation, scheduling, engagement, and analytics reporting.',
    icon: <SocialMediaIcon fontSize="large" color="primary" />,
  },
  {
    title: 'Training & Consultation',
    description: 'Training session & consultation for your team to enhance digital strategy with business objectives.',
    icon: <SchoolIcon fontSize="large" color="primary" />,
  },
  {
    title: 'Add Web Features',
    description: 'Customizable features based on requirement, providing users with seamless experience.',
    icon: <WebIcon fontSize="large" color="primary" />,
  },
  {
    title: 'Digital Automation Solutions',
    description: 'Implement automated digital workflows for repetitive tasks to improve efficiency in operations.',
    icon: <AutomationIcon fontSize="large" color="primary" />,
  },
  {
    title: 'MVP Creation',
    description: 'Get your idea in front of real customers fast. We build simple, effective MVPs to help you test and validate your product in the market quickly.',
    icon: <BuildIcon fontSize="large" color="primary" />,
  },
];

const AddOns = () => {
  const navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 250);
    }
  };

  const handleOnClick = (path: string, sectionId: string) => {
    navigate(path);
    scrollToSection(sectionId);
  }

  return (
    <Container>
      <Box sx={{ px: 3, py: 10 }} style={{ overflow: 'hidden' }}>
        <Slider {...sliderSettings}>
          <Box sx={{ px: 1, py: 3 }}>
            <StyledPaper elevation={3}>
              <Typography variant="h4" color="text.primary" gutterBottom>
                Add-Ons
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Depending on your needs, our add-ons can enhance core services for a more complete solution.
              </Typography>
              <GradientButton
                text="Get Customized Pricing"
                onClick={() => handleOnClick('/contact', 'contact-us')}
              />
            </StyledPaper>
          </Box>
          {addOnsData.map((addOn, index) => (
            <Box key={index} sx={{ px: 1, py: 3 }}>
              <StyledPaper elevation={3}>
                {addOn.icon}
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  color="text.primary">{addOn.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {addOn.description}
                </Typography>
              </StyledPaper>
            </Box>
          ))}
        </Slider>
      </Box>
    </Container>
  );
};

export default AddOns;