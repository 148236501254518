import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import styled, { createGlobalStyle } from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Container, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import FlagDisplay from './FlagDisplay';

const countries = ['Indonesia', 'United_States', 'Singapore', 'France', 'Norway', 'United_Kingdom', 'Russian_Federation'];

const SvgContainer = styled.div`
  max-width: 100%; /* Set max width to 100% */
  svg {
    width: 100%;
    height: auto;
  }
  ${countries.map(
  country => `
      svg .${country} {
        fill: blue;
        cursor: pointer;
        transition: fill 0.3s ease;
      }

      svg .${country}:hover {
        fill: darkblue;
      }
    `,
)}
`;

const GlobalStyle = createGlobalStyle`
  ${countries.map(
  country => `
      svg .${country} {
        fill: blue;
        cursor: pointer;
        transition: fill 0.3s ease;
      }

      svg .${country}:hover {
        fill: darkblue;
      }
    `,
)}
`;

export default function World() {
  const theme = useTheme();
  const [svgContent, setSvgContent] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  useEffect(() => {
    // Fetch the SVG file
    fetch('/images/world.svg')
      .then(response => response.text())
      .then(svg => {
        setSvgContent(svg);
      });
  }, []);

  const handleClick = (e: any) => {
    if (e.target.tagName === 'path' && countries.some(country => e.target.classList.contains(country))) {
      console.log('Country clicked:', e.target.getAttribute('class'));
    }
  };

  return (
    <div id="world">
      <GlobalStyle />
      <Container>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontSize: {
                xs: '2rem',   // For mobile screens (extra small)
                sm: '3rem',   // For small screens (tablets)
                md: '3.5rem',     // For larger screens (desktops)
              },
            }}
          >
            Enhance Your{' '}
            <Typography
              component="span"
              variant="h2"
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                fontSize: {
                  xs: '2rem',   // For mobile screens (extra small)
                  sm: '3rem',   // For small screens (tablets)
                  md: '3.5rem',     // For larger screens (desktops)
                },
              }}
            >
              Digital Productivity
            </Typography>
          </Typography>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontSize: {
                xs: '2rem',   // For mobile screens (extra small)
                sm: '3rem',   // For small screens (tablets)
                md: '3.5rem',     // For larger screens (desktops)
              },
            }}
          >
            to the New Heights
          </Typography>

          <Typography
            textAlign="center"
            color="text.primary"
            sx={{
              fontSize: '1.15rem',
              fontWeight: 500,
              lineHeight: 1.6,
              alignSelf: 'center',
              width: { sm: '100%', md: '80%' }
            }}
            dangerouslySetInnerHTML={{ __html: "We transform businesses globally through cutting-edge technological innovations. Successfully managed 142+ projects worldwide, enhancing app development productivity by overseeing and analyzing more than 100,000 HR assessments in real-time. " }}
          />
        </Stack>
      </Container>

      <FlagDisplay />

      <Box
        id="world"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
          width: '100%', // Full width of the viewport
          p: isMobile ? 0 : '100px', // Add padding on mobile
          margin: 0, // Remove margin
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              padding: '100px',
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? 'grey.200' : 'grey.800',
              borderRadius: '20px', // Rounded corners
              overflow: 'hidden', // Clip the SVG if it overflows
              width: '90%', // Full width
            }}
          >
            <SvgContainer
              id="svg-container"
              onClick={handleClick}
              dangerouslySetInnerHTML={{ __html: svgContent }}
            />
          </Box>
        )}

        {/* Cards Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack cards vertically on small screens
            justifyContent: 'space-between',
            position: isMobile ? 'static' : 'absolute', // Remove position on mobile
            bottom: { xs: '20px', sm: '30px' }, // Adjust based on your design
            left: isMobile ? 'auto' : '50%', // Remove left alignment on mobile
            transform: isMobile ? 'none' : 'translateX(-50%)', // Remove transform on mobile
            width: { xs: '100%', sm: '70%' }, // Responsive width of the Box
            gap: 2,
          }}
        >
          <Card sx={{ flex: 1, margin: '10px', height: '150px' }}>
            <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>25+</Typography>
              <Typography variant="body2">Website and Apps Developed</Typography>
            </CardContent>
          </Card>
          <Card sx={{ flex: 1, margin: '10px', height: '150px' }}>
            <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>10M+</Typography>
              <Typography variant="body2">Organic Traffic Increased</Typography>
            </CardContent>
          </Card>
          <Card sx={{ flex: 1, margin: '10px', height: '150px' }}>
            <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Typography variant="h3" component="div" sx={{ fontWeight: 'bold' }}>142+</Typography>
              <Typography variant="body2">Projects Delivered</Typography>
            </CardContent>
          </Card>
        </Box>
      </Box >
    </div>
  );
}
