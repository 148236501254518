import React from 'react';
import { Box } from '@mui/material'; // Import Box from Material-UI
import HomeHero from './HomeHero'; // Adjust import paths as necessary
import HeaderButtons from './HeaderButtons';
import Starfield from './Starfield';
import CursorImage from './CursorImage';

const LandingWrapper = () => {
  return (
    <Box
      id="landing-wrapper"
      sx={{
        height: '100vh', // Full height of the viewport
        width: '100%', // Full width of the viewport
        overflow: 'hidden', // Prevent content from overflowing
        display: 'flex', // Use flexbox for layout
        flexDirection: 'column', // Stack children vertically
      }}
    >
      <HomeHero />
      <div style={{ height: '10vh' }}></div>
      <HeaderButtons />
      <Starfield />
      <CursorImage />
    </Box>
  );
};

export default LandingWrapper;
