import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-family: 'Audiowide', sans-serif;
  font-size: 4vw; /* Responsive font size */
  color: #eed6fe;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 1000;
`;

const Description = styled.p`
  font-size: 2vw; /* Responsive font size */
  color: #eed6fe;
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 1000;
`;

const firstColor = 'rgba(8, 10, 200, 0.1)';
const secondColor = 'rgba(8, 4, 12, 0.1)';

const Canvas = styled.canvas`
  background: linear-gradient(
    to bottom,
    ${firstColor} /* First Linear 30% */,
    ${secondColor} /* Second Linear 30% */
  );
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  width: 100%; /* Make canvas full width */
  height: 100%; /* Make canvas full height */
`;

const StarField = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const starField = useRef<any[]>([]);
  const warpSpeed = useRef<number>(0);
  const xMod = useRef<number>(0);
  const yMod = useRef<number>(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctxRef.current = canvas.getContext('2d');
      init();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    // document.addEventListener('keydown', handleKeyDown);
    // document.addEventListener('keyup', handleKeyUp);
    // document.addEventListener('mousedown', handleMouseDown);
    // document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      // document.removeEventListener('keydown', handleKeyDown);
      // document.removeEventListener('keyup', handleKeyUp);
      // document.removeEventListener('mousedown', handleMouseDown);
      // document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const handleResize = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
        warpSpeed.current = 1;
        break;
      case 'ArrowLeft':
        xMod.current < 6 ? (xMod.current += 0.3) : (xMod.current = 6);
        break;
      case 'ArrowUp':
        yMod.current < 6 ? (yMod.current += 0.3) : (yMod.current = 6);
        break;
      case 'ArrowRight':
        xMod.current > -6 ? (xMod.current -= 0.3) : (xMod.current = -6);
        break;
      case 'ArrowDown':
        yMod.current > -6 ? (yMod.current -= 0.3) : (yMod.current = -6);
        break;
    }
    event.preventDefault();
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
        warpSpeed.current = 0;
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        xMod.current = 0;
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        yMod.current = 0;
        break;
    }
    event.preventDefault();
  };

  const handleMouseDown = () => {
    warpSpeed.current = 1;
  };

  const handleMouseUp = () => {
    warpSpeed.current = 0;
  };

  const handleTouchStart = (event: TouchEvent) => {
    event.preventDefault();
    warpSpeed.current = 1;
  };

  const handleTouchEnd = () => {
    warpSpeed.current = 0;
  };

  const init = () => {
    for (let i = 0; i < 200; i++) {
      starField.current.push(new Star());
    }
    window.requestAnimationFrame(draw);
  };

  const draw = () => {
    const ctx = ctxRef.current;
    if (ctx) {
      // Clear the canvas with the gradient background
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      // Apply the gradient background
      const gradient = ctx.createLinearGradient(0, 0, 0, window.innerHeight);
      gradient.addColorStop(0, firstColor); // First Linear 30%
      gradient.addColorStop(1, secondColor); // Second Linear 30%
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

      // Draw stars
      for (const star of starField.current) {
        ctx.fillStyle = `rgb(${star.color}, ${star.color}, ${star.color})`;
        ctx.fillRect(star.x, star.y, star.color / 128, star.color / 128);
        star.updatePos();
      }
    }
    window.requestAnimationFrame(draw);
  };

  class Star {
    x: number;
    y: number;
    color: number;

    constructor() {
      this.x = Math.random() * window.innerWidth;
      this.y = Math.random() * window.innerHeight;
      this.color = 0;
    }

    updatePos() {
      const speedMult = warpSpeed.current ? 0.028 : 0.02;
      this.x += xMod.current + (this.x - (window.innerWidth / 2)) * speedMult;
      this.y += yMod.current + (this.y - (window.innerHeight / 2)) * speedMult;
      this.updateColor();

      if (this.x > window.innerWidth || this.x < 0) {
        this.x = Math.random() * window.innerWidth;
        this.color = 0;
      }
      if (this.y > window.innerHeight || this.y < 0) {
        this.y = Math.random() * window.innerHeight;
        this.color = 0;
      }
    }

    updateColor() {
      if (this.color < 255) {
        this.color += 5;
      } else {
        this.color = 255;
      }
    }
  }

  return (
    <>
      <Canvas ref={canvasRef} />
    </>
  );
};

export default StarField;
