import { Button, SxProps, Theme } from "@mui/material";
import PropTypes from "prop-types";
import { FC } from "react";

interface GradientButtonProps {
  text: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const GradientButton: FC<GradientButtonProps> = ({ text, onClick, sx }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        mb: 2,
        background: 'linear-gradient(45deg, #3f51b5 30%, #2196f3 90%)',
        color: '#fff',
        '&:hover': {
          background: 'linear-gradient(45deg, #303f9f 30%, #1976d2 90%)',
        },
        ...sx, // Merges any additional sx styles passed in
      }}
    >
      {text}
    </Button>
  );
};

GradientButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

GradientButton.defaultProps = {
  onClick: () => { },
};

export default GradientButton;
