import React from 'react';
import { Divider, PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../../getLPTheme';
import Hero from '../../components/Hero';
import CaseStudy from '../../components/ProductsAndServices/CaseStudy';
import { useThemeMode } from '../../hooks/ThemeModeContext';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';

const images = [
  {
    url: '/images/sharpish_logo.png',
    height: 400,
  },
  {
    url: '/images/sharpish_colors.png',
    height: 480,
  },
  {
    url: '/images/sharpish_font.png',
    height: 440,
  }
]

const UiuxDesign = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Hero prefixTitle='Cases:' title={'UI/UX & Design'} description={`Explore our UI/UX design case studies, where we've helped startups and established brands elevate their digital presence`} />
        <CaseStudy
          title="UI/UX & Design Case Study 1"
          subTitle="Sharpish UI/UX Design"
          problem={`A newly launched startup faced the challenge of establishing a strong and recognizable brand identity in a competitive market. They needed to elevate their products by creating a cohesive visual design that would resonate with their target audience and differentiate them from competitors. The startup required a complete digital design solution, including a logo, color palette, and user interface (UI) that would reflect their brand values and appeal to potential customers.`}
          solution={`We collaborated closely with the startup to understand their brand vision, target market, and unique value proposition. Our design team created a comprehensive digital design package, starting with a distinctive logo that encapsulated the brand's identity. We then developed a carefully selected color palette that conveyed the desired emotions and aligned with the brand’s message. Additionally, we designed a user-friendly UI that ensured a seamless and engaging experience for their customers across all digital platforms.`}
          result={`The result was a cohesive and professional brand identity that significantly elevated the startup’s product presentation. The new design not only attracted attention but also fostered trust and recognition among users, helping the startup to establish a strong foothold in the market and drive customer engagement from the outset.`}
          images={images}
        />
        <ContactUs />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default UiuxDesign;
