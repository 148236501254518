import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DesignServices from '@mui/icons-material/DesignServices';
import AddBusiness from '@mui/icons-material/AddBusiness';
import TabletAndroid from '@mui/icons-material/TabletAndroid';
import ImageDisplayer from './ImageDisplayer';
import Chips from './Chips';

const items = [
  {
    icon: <TabletAndroid />,
    title: 'Web Application',
    description:
      'We develop custom web applications that meet your business needs, delivering seamless functionality and engaging user experiences.',
    // imageLight: 'https://cdn-icons-png.flaticon.com/512/1077/1077114.png',
    // imageDark: 'https://cdn-icons-png.flaticon.com/512/1077/1077114.png',
    imageLight: <TabletAndroid sx={{ fontSize: 240 }} />,
    imageDark: <TabletAndroid sx={{ fontSize: 240 }} />,
    link: '/services/web-application',
  },
  {
    icon: <AddBusiness />,
    title: 'SEO & Marketing',
    description:
      'Boost your online visibility and attract your target audience with our tailored SEO strategies and data-driven marketing solutions.',
    imageLight: <AddBusiness sx={{ fontSize: 240 }} />,
    imageDark: <AddBusiness sx={{ fontSize: 240 }} />,
    link: '/services/seo-marketing',
  },
  {
    icon: <DesignServices />,
    title: 'UI/UX & Design',
    description:
      'Transform your digital presence with intuitive UI/UX designs and visually compelling graphics that resonate with your audience.',
    imageLight: <DesignServices sx={{ fontSize: 240 }} />,
    imageDark: <DesignServices sx={{ fontSize: 240 }} />,
    link: '/services/uiux-design',
  },
]

export default function Services() {
  return (
    <>
      <Chips title="Services" subTitle="Explore our comprehensive range of services designed to propel your business forward. Whether you need custom web applications, enhanced online visibility, or captivating design, our expertise ensures your success in a digital-first world." items={items} />
    </>
  )
}
