import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AnotherPage from './pages/AnotherPage';
import LandingPage from './pages/LandingPage';
import Services from './pages/Services';
import WebApplication from './pages/Services/WebApplication';
import UiuxDesign from './pages/Services/UiuxDesign';
import Seo from './pages/Services/Seo';
import ProductsPage from './pages/Products';
import ContactUsPage from './pages/ContactUs';
import LeadershipPage from './pages/LeadershipPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import HrTools from './pages/Products/HrTools';
import StarUp from './pages/Products/StarUp';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/another" element={<AnotherPage />} />

            <Route path="/products" element={<ProductsPage />} />
            <Route path="/products/hr-tools" element={<HrTools />} />
            <Route path="/products/star+up" element={<StarUp />} />

            <Route path="/services" element={<Services />} />
            <Route path="/services/web-application" element={<WebApplication />} />
            <Route path="/services/seo-marketing" element={<Seo />} />
            <Route path="/services/uiux-design" element={<UiuxDesign />} />

            <Route path="/leadership" element={<LeadershipPage />} />

            <Route path="/contact" element={<ContactUsPage />} />

            <Route path="/terms" element={<TermsAndConditionsPage />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
