import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import { useThemeMode } from '../hooks/ThemeModeContext';

export default function Footer() {
  const { mode, toggleColorMode } = useThemeMode();
  const navigate = useNavigate();

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 250);
    }
  };

  const handleOnClick = (path: string, sectionId: string) => {
    navigate(path);
    scrollToSection(sectionId);
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            gap: 1,
          }}
        >
          <img
            src={'/images/adn_logo_primary_light.svg'}
            alt="Logo"
            style={{ height: '24px', marginRight: '16px', cursor: 'pointer' }}
            onClick={() => handleOnClick('/', 'landing-wrapper')}
          />
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            gap: 2,
          }}
        >
          <div>
            <Link
              color="text.primary"
              variant="body2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOnClick('/', 'client-logo-banner')}
              href=""
            >
              About Us
            </Link>
          </div>
          <div>
            <Link
              color="text.primary"
              variant="body2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOnClick('/', 'service-cards')}
              href=""
            >
              Services
            </Link>
          </div>
          <div>
            <Link
              color="text.primary"
              variant="body2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOnClick('/', 'our-products')}
              href=""
            >
              Product
            </Link>
          </div>
          <div>
            <Link
              color="text.primary"
              variant="body2"
              style={{ cursor: 'pointer' }}
              onClick={() => handleOnClick('/contact', 'contact-us')}
              href=""
            >
              Contact Us
            </Link>
          </div>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            gap: 1,
          }}
        >
          {/* <IconButton
            color="inherit"
            href="https://github.com/phitonthel"
            aria-label="GitHub"
            target="_blank"
            rel="noopener noreferrer" // Recommended for security
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton> */}
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/akselerasi-digital-nusantara"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener noreferrer" // Recommended for security
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography sx={{ display: 'inline', mx: 0.5, color: 'text.secondary' }}>
          {/* copyright symbol */}
          &copy;
          PT Akselerasi Digital Nusantara
        </Typography>
        <Typography sx={{ display: 'inline', mx: 0.5, color: 'text.primary' }}>
          &nbsp;•&nbsp;
        </Typography>
        <Link
          color="text.secondary"
          variant="body2"
          style={{ cursor: 'pointer' }}
          onClick={() => handleOnClick('/terms', 'terms-and-conditions')}
          href=""
        >
          Privacy Policy
        </Link>
        <Typography sx={{ display: 'inline', mx: 0.5, color: 'text.primary' }}>
          &nbsp;•&nbsp;
        </Typography>
        <Link
          color="text.secondary"
          variant="body2"
          style={{ cursor: 'pointer' }}
          onClick={() => handleOnClick('/terms', 'terms-and-conditions')}
          href=""
        >
          Terms and Conditions
        </Link>
      </Box>
    </Container>
  );
}
