import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function HomeHero() {
  return (
    <Box
      id="homehero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 8, sm: 16 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: '100%' }}>
          <Typography
            variant="h1"
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                fontSize: {
                  xs: '2.5rem',   // For mobile screens (extra small)
                  sm: '3.5rem',   // For small screens (tablets)
                  md: '4rem',     // For larger screens (desktops)
                },
                fontWeight: 'bold',
                lineHeight: 1,
              }}
            >
              Accelerate
            </Typography>
            {' '}
            <Typography
              component="span"
              sx={{
                color: 'white',
                fontSize: {
                  xs: '2.5rem',   // For mobile screens (extra small)
                  sm: '3.5rem',   // For small screens (tablets)
                  md: '4rem',     // For larger screens (desktops)
                },
                fontWeight: 'bold',
                lineHeight: 1,
              }}
            >
              Your Business with Data-Driven Digital Solutions
            </Typography>
          </Typography>

          <Typography
            variant="h1"
            textAlign="center"
            color="white"
            sx={{
              alignSelf: 'center',
              width: { xs: '90%', sm: '80%' }, // Adjust width to prevent overflow
              fontSize: {
                xs: '1rem',    // Mobile font size
                sm: '1.125rem', // Tablet font size
                md: '1.25rem', // Larger screen font size
              },
              lineHeight: 1.6, // Line height for better spacing
              px: { xs: 2, sm: 0 }, // Horizontal padding for mobile
              fontWeight: 'normal', // Override bold styling
            }}
            dangerouslySetInnerHTML={{
              __html: "Transform Your Business with Our Innovative <b>Web Development</b>, <b>Data-Driven Marketing</b>, and <b>Exceptional Design Solutions</b>. Harness the power of cutting-edge technology, tailored software systems, and strategic insights to elevate your digital presence and drive measurable success.",
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
}
