import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';

import FAQ from './FAQ';
import CallToActionBox from './Home/CallToActionBox';
import Footer from './Footer';
import getLPTheme from '../getLPTheme';
import { WhiteToBlackRoundedShape } from '../atoms/BWTransition';

export const DarkFooter = () => {
  const darkLPTheme = createTheme(getLPTheme('dark'));
  return (
    <ThemeProvider theme={darkLPTheme}>
      <WhiteToBlackRoundedShape />
      <Box sx={{ bgcolor: 'background.default' }}>
        <CallToActionBox />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}