import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import Assessment from '@mui/icons-material/Assessment';
import Chips from './Chips';

const items = [
  {
    icon: <Assessment />,
    title: 'HR Tools',
    description:
      `Our HR Tool streamlines the assessment process, offering features like 360-degree feedback and attendance tracking. Enhance your company's HR operations by making data-driven decisions and improving employee engagement.`,
    // imageLight: <Assessment sx={{ fontSize: 240 }} />,
    // imageDark: <Assessment sx={{ fontSize: 240 }} />,
    imageLight: 'images/services/hrtools.png',
    imageDark: 'images/services/hrtools.png',
    link: '/products/hr-tools',

  },
  {
    icon: <RocketLaunch />,
    title: 'Star+Up',
    description:
      `Star+Up is your one-stop solution for launching a successful startup. From logo design to web development, SEO, and pitch decks, we provide the essential tools to help your business get off the ground and thrive in a competitive market.`,
    // imageLight: <RocketLaunch sx={{ fontSize: 240 }} />,
    // imageDark: <RocketLaunch sx={{ fontSize: 240 }} />,
    imageLight: 'images/services/starup.png',
    imageDark: 'images/services/starup.png',
    link: '/products/star+up',
  },
];

interface ChipProps {
  selected?: boolean;
}

export default function Products() {
  return (
    <>
      <Box
        id="our-products"
        sx={{
          pb: { xs: 8, sm: 16 },
        }}
      >
        <Chips title="Our Products" subTitle="With 90% of startups failing and average launch costs over $3,000, the stakes are high. We provide the tools to help you succeed and overcome these challenges." items={items} />
      </Box>
    </>
  )
}
