import React from 'react';
import { CardMedia, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, PaletteMode, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../../getLPTheme';
import Hero from '../../components/Hero';
import CaseStudy from '../../components/ProductsAndServices/CaseStudy';
import { useThemeMode } from '../../hooks/ThemeModeContext';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';
import StatiscticPaper from '../../atoms/StatiscticPaper';
import { AccessTime, People, TrendingUp } from '@mui/icons-material';

const images = [
  {
    url: '/images/idx_ts_graph.png',
    height: 440,
  },
  {
    url: '/images/idx_ts_feedbacktable.png',
    height: 400,
  }
]

const WebApplication = () => {
  const { mode, toggleColorMode } = useThemeMode();
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Hero prefixTitle={`Web`} title={'Application'} description={`Explore our diverse range of web application case studies, where we've tackled complex challenges and delivered tailored solutions that drive results`} />

        <Container sx={{ py: 6 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Boosting Team Efficiency for a Global Consulting Firm
          </Typography>

          <Typography variant="h6">
            We've worked with over 5+ organizations across multiple industries, helping them enhance employee engagement and streamline feedback loops. One of our notable achievements involved implementing our HR tools for a Indonesian Stock Exchange, resulting in significant efficiency gains and improved team dynamics.
          </Typography>

          <Grid container spacing={2} justifyContent="center" sx={{ mt: 10 }}>
            <Grid item xs={12} md={4}>
              <StatiscticPaper title="110%+" subTitle="Increase in Feedback Participation" minHeight={200} fontSize='3rem' backgroundColor='#0874e4' />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatiscticPaper title="66%+" subTitle="Reduction in Feedback Cycle Time" minHeight={200} fontSize='3rem' backgroundColor='#0874e4' />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatiscticPaper title="2 Months" subTitle="Implementation" minHeight={200} fontSize='3rem' backgroundColor='#0874e4' />
            </Grid>
          </Grid>

          <Typography variant="h5" sx={{ mt: 8 }}>
            What Problem Did They Face?
          </Typography>
          <Typography>
            The firm had been struggling with a sluggish and fragmented feedback process that lacked real-time insights. Feedback participation was low, and employees were dissatisfied with the time-consuming procedures. This led to a significant bottleneck in performance reviews, with key feedback often delayed, which impacted employee development and team dynamics.
          </Typography>

          <Typography variant="h5" sx={{ mt: 8 }}>
            Our Tailored Solution
          </Typography>
          <Typography>
            We deployed our customized 360 Feedback HR tool, tailored to the firm’s unique team structures and performance criteria. The solution integrated real-time feedback collection with intuitive dashboards for HR teams, managers, and employees. Additionally, automated reminders and streamlined processes boosted engagement, while detailed reporting helped identify growth opportunities for individuals and teams.
          </Typography>

          <Typography variant="h5" sx={{ mt: 8 }}>
            The Results
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <TrendingUp color="primary" /> {/* Icon for growth or increase */}
              </ListItemIcon>
              <ListItemText
                primary="40% increase in feedback participation across all departments due to real-time accessibility and automated prompts."
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <AccessTime color="secondary" /> {/* Icon for time-related improvements */}
              </ListItemIcon>
              <ListItemText
                primary="Reduced the feedback cycle time by 30%, allowing managers to access timely insights for quicker decision-making and growth tracking."
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <People color="success" /> {/* Icon for community or culture */}
              </ListItemIcon>
              <ListItemText
                primary="Created a more engaged, feedback-driven culture that enhanced employee performance and satisfaction across the organization."
              />
            </ListItem>
          </List>

          {/* <CardMedia
            component="img"
            image={'/images/seo_chart_case_study.svg'}
            alt={`seo_chart_case_study.svg`}
            sx={{ objectFit: 'cover', mt: 8 }}
          /> */}

        </Container>
        {/* <CaseStudy
          title={`Web App Case Study 1`}
          subTitle={`HR Tools for Indonesian Stock Exchange`}
          problem={`The Indonesian Stock Exchange (IDX) faced a complex challenge in assessing over 500 employees across various departments. The assessment process required customization for different roles and positions, with each department necessitating unique evaluation criteria and formulas. Additionally, IDX needed to generate detailed and complex reports, including CSV files and graphical representations, to provide key metrics for individual employees and departmental performance.`}
          solution={`To address these challenges, we developed a highly customized web application tailored specifically to IDX's needs. The application allowed each user to log in and automatically access the appropriate assessment mapped to their role and position. Additionally, administrators were given the capability to manually assign assessments on behalf of employees, ensuring flexibility and comprehensive coverage. The system was also designed to generate complex reports in various formats, including CSV and detailed graphs, to meet the organization's reporting requirements.`}
          result={`The implementation of our customized web application significantly boosted productivity at IDX. The system successfully managed and compiled over 30,000 assessments in real-time, enabling prompt and accurate reporting to directors. This improvement in efficiency not only streamlined the assessment process but also provided valuable insights into employee performance and departmental effectiveness, supporting informed decision-making across the organization.`}
          images={images}
        /> */}
        <ContactUs />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default WebApplication;
