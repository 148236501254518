import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useThemeMode } from '../../hooks/ThemeModeContext';

const staticLogos = [
  'images/logos/atourin.svg',
  'images/logos/bountie.svg',
  'images/logos/dale.svg',
  'images/logos/danamas.svg',
  'images/logos/dimensy.svg',
  'images/logos/efishery.svg',
  'images/logos/ginee.svg',
  'images/logos/gotrade.svg',
  'images/logos/sewatama.svg',
  'images/logos/shell.svg',
  'images/logos/tanifund.svg',
  'images/logos/yellow.svg',
  'images/logos/wow.svg',
];

const MULTIPLIER = 10;
const SPEED = 90 // in seconds

const getStaticLogos = (repeatCount: number) => {
  return Array.from({ length: repeatCount }, () => staticLogos).flat();
};

const LogoBanner = () => {
  const { mode } = useThemeMode();
  const [logos, setLogos] = useState(getStaticLogos(MULTIPLIER));

  useEffect(() => {
    // Update logos whenever the mode changes
    setLogos(getStaticLogos(MULTIPLIER));
  }, [mode]);

  return (
    <>
      <Typography
        id="client-logo-banner"
        component="p"
        variant="h6"
        align="center"
        sx={{ color: 'text.secondary', marginTop: '2%', marginBottom: '1%' }}
      >
        Loved and trusted by top companies around.
      </Typography>
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%', // Full width
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            animation: `scroll ${SPEED}s linear infinite`, // Adjust the duration for speed
          }}
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index + 1}`}
              style={{
                width: '300px', // Adjust logo size
                marginRight: '20px', // Space between logos
                filter: mode === 'light' ? 'none' : 'brightness(0.5) invert(1)', // Change color to white for dark mode
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'inline-block',
            animation: `scroll ${SPEED}s linear infinite`, // Duplicate the logos for seamless effect
          }}
        >
          {logos.map((logo, index) => (
            <img
              key={index + logos.length} // Unique key for duplicated logos
              src={logo}
              alt={`Logo ${index + 1}`}
              style={{
                width: '300px', // Adjust logo size
                marginRight: '20px', // Space between logos
                filter: mode === 'light' ? 'none' : 'brightness(0) invert(1)', // Change color to white for dark mode
              }}
            />
          ))}
        </Box>

        {/* Add CSS for scrolling animation */}
        <style>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
      </Box>
    </>
  );
};

export default LogoBanner;
