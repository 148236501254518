import { Paper, Typography } from "@mui/material";

const StatiscticPaper = ({
  title,
  subTitle,
  minHeight,
  fontSize,
  backgroundColor,
}: {
  title: string,
  subTitle: string
  minHeight?: number
  fontSize?: string
  backgroundColor?: string
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: backgroundColor ?? undefined,
        p: 4,
        minHeight: {
          xs: minHeight ?? 200, // Smaller height on mobile
          sm: minHeight ?? 200, // Medium height on tablet
          md: minHeight ?? 350, // Default or custom height on desktop
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: 'bold',
          fontSize: {
            xs: '4rem', // Small screen (mobile)
            sm: '5rem', // Medium screen (tablet)
            md: fontSize ?? '6rem', // Large screen (desktop)
          },
        }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" sx={{ alignSelf: 'flex-end' }}>
        {subTitle}
      </Typography>
    </Paper>
  )
}

export default StatiscticPaper;
